import React from "react";
import { StyleSheet, Text, View, FlatList, Image, TouchableOpacity, Dimensions } from "react-native";
import Swal from 'sweetalert2';
import { isMobile } from "react-device-detect";
import $ from 'jquery'
import Loader from "./components/Loader";
import Container from "./components/Container";
import { useParams, useHistory } from "react-router-dom";
import RoomBox from "./components/RoomBox";

export default function FindRooms({ socket }) {

    const params = useParams()
    const history = useHistory()

    const [loading, setLoading] = React.useState(true)
    const [connected, setConnected] = React.useState(true)
    const [rooms, setRooms] = React.useState([])

    React.useEffect(() => {
        document.title = `Find A Game | Cards Lacking Originality`
    
        socket.on('join room', (data) => goToRoom(data.code))
        socket.on('connect_error', () => setConnected(false))
        socket.on('error', logError)

        fetchRooms()
    
        return () => {
            socket.removeAllListeners()
        }
    }, [])

    function joinRoom(code) {
        if (!connected) {
            return logError("Could not connect to server. Try again later.")
        }
    
        if (!code) {
            return logError("Please enter a room code.")
        }
        socket.emit('join room', code)
    }

    function goToRoom(code) {
        history.push(`/join/${code}`)
    }

    async function fetchRooms() {
        try {
            setLoading(true)
            const res = await $.ajax({
              type: 'GET',
              url: `${process.env.REACT_APP_SERVER_URL}/api/rooms/get`
            })
      
            if (!res.success) throw Error(res.message || "Error connecting to server. Try again later.")
      
            setLoading(false)
            setRooms(res.rooms)
          } catch (err) {
            console.log(err)
            setLoading(false)
            logError(err.message)
          }
    }

    function logError(text) {
        setLoading(false)
        Swal.fire("Error", text, 'error')
    }

    return (
        <Container logoPressable={true}>
            <View style={styles.container}>
                <Text style={styles.headingText}>Find A Game</Text>
                <Text style={styles.subtitleText}>Discover currently joinable rooms in Cards Lacking Originality.</Text>
                <View style={styles.content}>
                    {loading && <Loader textVisible={true} color={'#000'} />}
                    {!loading && rooms.length === 0 && <Text style={styles.subtitleText}>No joinable rooms. Check back later!</Text>}
                    <FlatList
                        contentContainerStyle={styles.listContent}
                        data={rooms}
                        renderItem={({ item }) => <RoomBox properties={item} onJoin={joinRoom}/>}
                    />
                </View>
            </View>
        </Container>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 30
    },
    content: {
        marginVertical: 20,
    },
    headingText: {
        fontSize: 30,
        fontWeight: '700',
    },
    subtitleText: {
        fontSize: 18,
        fontWeight: '500',
        marginVertical: 10
    },
    listContent: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    button: {
        flexDirection: 'row',
        width: 270,
        backgroundColor: '#000',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 15,
        paddingHorizontal: 25,
        marginVertical: 20
    },
    buttonText: {
        fontSize: 18,
        fontWeight: '700',
        color: '#fff',
        marginLeft: 10
    }
});