import { StyleSheet, View } from 'react-native'
import Navbar from './Navbar';
import { isMobile } from "react-device-detect";

const HEIGHT = !isMobile ? 70 : 50

export default function Container({children, logoPressable}) {
    return (
        <View style={styles.container}>
            <Navbar logoPressable={logoPressable}/>
            <View style={styles.content}>
                {children}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    content: {
        flex: 1,
        marginTop: HEIGHT
    },
})