import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import LOGO from './../assets/clo_logo_long_white.png'
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
const HEIGHT = !isMobile ? 70 : 50

export default function Navbar({logoPressable = true}) {
    const history = useHistory()

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => history.push('/')} disabled={!logoPressable}>
                <Image source={LOGO} style={styles.logo}/>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        padding: 5,
        backgroundColor: '#000',
        justifyContent: 'center',
        alignItems: 'center',
        height: HEIGHT,
        zIndex: 99
    },
    logo: {
        resizeMode: "contain",
        justifyContent: "center",
        height: HEIGHT - 15,
        width: 270,
        margin: 20
      },
})