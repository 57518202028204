import React from "react";
import { StyleSheet, Text, View, TextInput, Image, TouchableOpacity, Dimensions } from "react-native";
import Swal from 'sweetalert2';
import Game from "./Game";
import LOGO from './assets/clo_logo.png'
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import config from "../package.json"
import { useParams, useHistory } from "react-router-dom";
import Loader from "./components/Loader";
const CODE_LENGTH = 4

export default function Main({ socket }) {

  const params = useParams()
  const history = useHistory()

  const [connected, setConnected] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [roomCode, setRoomCode] = React.useState(params.roomCode || "")
  const [room, setRoom] = React.useState(null)

  React.useEffect(() => {
    document.title = `Cards Lacking Originality`

    if (params.roomCode) {
      joinRoom(params.roomCode)
    }

    socket.on('join room', (data) => setRoom(data))
    socket.on('connect_error', () => setConnected(false))
    socket.on('server disconnected', (data) => {
      setRoom(null)
      logError(data.message)
    })
    socket.on('error', logError)

    return () => {
      socket.removeAllListeners()
    }
  }, [])

  React.useEffect(() => {
    if (!connected && room) {
      setRoom(null)
      logError('Connection to server was lost.')
    }
  }, [connected, room])

  function joinRoom(code) {
    if (!connected) {
      return logError("Could not connect to server. Try again later.")
    }

    setLoading(true)
    if (!code) {
      return logError("Please enter a room code.")
    }
    socket.emit('join room', code)
  }

  function createRoom() {
    if (!connected) {
      return logError("Could not connect to server. Try again later.")
    }

    setLoading(true)
    socket.emit('create room', (room) => {
      setRoom(room)
    })
  }

  function launchFindGamesPage() {
    history.push('/rooms/find')
  }

  function showLegalStuff() {
    Swal.fire({
      title: "Credit Attribution",
      icon: 'info',
      html: "All credit for card content is attributed to " + "<a href='https://cardsagainsthumanity.com/' style='color: #545454' target='_blank'>Cards Against Humanity LLC</a>" + " and is licensed under " + "<a href='https://creativecommons.org/licenses/by-nc-sa/4.0/' style='color: #545454' target='_blank'>CC BY-NC-SA 4.0</a>" + "."
    })
  }

  function showPost() {
    history.push('/to-our-users')
  }

  function logError(text) {
    setLoading(false)
    Swal.fire("Error", text, 'error')
  }

  if (room) return <Game socket={socket} roomData={room}/>

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Image source={LOGO} style={styles.logo}/>
        <RoomCodeBox value={roomCode} onChangeText={setRoomCode} onSubmitEditing={() => joinRoom(roomCode)} loading={loading}/>
        <Text style={styles.headingText}>{"OR"}</Text>
        <Button title={'Host New Game'} onPress={createRoom} loading={loading} />
        {/* <TouchableOpacity onPress={showPost}>
          <Text style={styles.subtitleText}>Update: To Our Users</Text>
        </TouchableOpacity> */}
        <Button title={'Find A Game'} onPress={launchFindGamesPage} />
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerText}>{`Version ${config.version}`}</Text>
        {!isMobile && 
          <a href="https://www.producthunt.com/posts/cards-lacking-originality?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cards-lacking-originality" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=178757&theme=light" alt="Cards Lacking Originality - A simple and elegant online Cards Against Humanity clone | Product Hunt Embed" style={{width: "200px", height: "43.2px"}} /></a>
        }
        <TouchableOpacity onPress={showLegalStuff}>
          <Text style={styles.footerText}>Legal Stuff</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

function Button({title, onPress, loading}) {
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      {!loading ? (
        <Text style={styles.buttonText}>{title}</Text>
      ) : (
        <Loader/>
      )}
    </TouchableOpacity>
  )
}

function RoomCodeBox({ value, onChangeText, onSubmitEditing, loading }) {
  const [textValue, setTextValue] = React.useState(value)

  React.useEffect(() => {
    onChangeText(textValue)
  }, [textValue, onChangeText])

  return (
    <View style={roomCodeBoxStyles.container}>
      <TextInput 
        style={roomCodeBoxStyles.inputField} 
        placeholder={'Enter Room Code'}
        value={textValue} 
        onChangeText={(text) => text.length <= CODE_LENGTH && setTextValue(text.toUpperCase())} 
        onSubmitEditing={onSubmitEditing}
      />
      <TouchableOpacity style={roomCodeBoxStyles.button} onPress={onSubmitEditing}>
        {!loading ? <FontAwesomeIcon icon={faArrowRight} size={20} color={'#fff'} /> : <FontAwesomeIcon icon={faSpinner} fontSize={20} color={'#fff'} spin={true} />}
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    maxHeight: Dimensions.get('window').height
  },
  content: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: !isMobile ? 20 : 0
  },
  logo: {
    resizeMode: "contain",
    justifyContent: "center",
    height: !isMobile ? 250 : 175,
    width: !isMobile ? 450 : 315,
    margin: 20
  },
  headingText: {
    fontSize: 30,
    fontWeight: '700',
    marginVertical: !isMobile ? 25 : 10
  },
  subtitleText: {
    fontSize: 18,
    fontWeight: '600',
    color: '#808080',
    marginVertical: 10,
    textDecorationLine: 'underline'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    padding: 30,
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  footerText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#919191'
  },
  button: {
    flexDirection: 'row',
    width: 270,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 15,
    paddingHorizontal: 25,
    marginVertical: 20
  },
  buttonText: {
    fontSize: 18,
    fontWeight: '700',
    color: '#fff',
    marginLeft: 10
  }
});

const roomCodeBoxStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginVertical: 15
  },
  inputField: {
    padding: 12,
    width: 220,
    fontSize: 16,
    borderColor: '#000',
    borderWidth: 2.5,
    fontWeight: '700'
  },
  button: {
    backgroundColor: '#000',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center'
  }
});
