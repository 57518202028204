import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main';
import reportWebVitals from './reportWebVitals';
import { Client } from 'gamekitjs'
import { Route, BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import Update from './Update';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import FindRooms from './FindRooms';

Sentry.init({
  dsn: "https://8074a8ae37934099b393768b085f4921@o372359.ingest.sentry.io/5722232",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5
});

const client = new Client(process.env.REACT_APP_SERVER_URL)
const socket = client.socket

Sentry.init({ dsn: "https://examplePublicKey@o0.ingest.sentry.io/0" });

const routing = (
  <Router>
    <Switch>
      <Route exact path="/join"><Redirect to="/"/></Route>
      <Route exact path="/join/:roomCode" render={({roomCode}) => <Main socket={socket} defaultRoomCode={roomCode} />} />
      <Route exact path="/to-our-users"><Update/></Route>
      <Route exact path="/rooms/find"><FindRooms socket={socket}/></Route>
      <Route exact path="/"><Main socket={socket} /></Route>
      <Route path="/"><Redirect to="/"/></Route>
    </Switch>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();