import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity, Dimensions } from 'react-native'
import { isMobile } from 'react-device-detect'
import Loader from './Loader'

export default function RoomBox({ properties, onJoin }) {

    const [loading, setLoading] = React.useState(false)

    function joinRoom() {
        setLoading(true)
        onJoin(properties.code)
    }

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <Text style={styles.headerText}>{`Room ${properties.code} (${properties.players.length}/${properties.maxPlayers})`}</Text>
                <Text style={styles.subtitleText}>{`In Progress: ${properties.inProgress ? 'Yes' : 'No'}`}</Text>
            </View>
            <Button title={"Join Room"} loading={loading} onPress={joinRoom} />
        </View>
    )
}

function Button({title, onPress, loading, disabled}) {
    return (
      <TouchableOpacity style={styles.button} onPress={onPress} disabled={disabled}>
        {!loading ? (
          <Text style={styles.buttonText}>{title}</Text>
        ) : (
          <Loader/>
        )}
      </TouchableOpacity>
    )
  }

const styles = StyleSheet.create({
    container: {
        padding: 20,
        margin: 15,
        marginVertical: isMobile && 15,
        marginLeft: 5,
        width: !isMobile ? 400 : Dimensions.get('window').width - 70,
        height:!isMobile ? 400 * 0.6 : (Dimensions.get('window').width - 70) * 0.6,
        shadowOffset: {
            width: 2,
            height: 2
        },
        shadowColor: 'grey',
        shadowRadius: 7,
    },
    content: {
        flexGrow: 1,
    },
    headerText: {
        fontSize: 25,
        fontWeight: '700',
    },
    subtitleText: {
        fontSize: 16,
        fontWeight: '500',
        marginVertical: 10
    },
    button: {
        flexDirection: 'row',
        backgroundColor: '#000',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 25
    },
    buttonText: {
        fontSize: 18,
        fontWeight: '600',
        color: '#fff',
        marginLeft: 10
    }
})