import { StyleSheet, View } from 'react-native'
import { ClickableCard } from './Card'
import { isMobile } from 'react-device-detect'

export default function Deck({cards, onCardPress, selectedCards, disabled}) {

    function renderCards() {
        const cardObjects = cards.map((card, index) => {
            const isSelected = selectedCards && selectedCards.find(el => el.text === card.text)
            return <ClickableCard 
                key={`playercard-${index}`} 
                text={card.text} 
                type={'white'} 
                onPress={onCardPress} 
                scale={!isMobile ? 0.95 : 0.8} 
                disabled={disabled || isSelected}
            />
        })
        return cardObjects
    }

    return (
        <View style={styles.container}>
            {renderCards()}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        padding: 5,
        overflow: 'scroll',
        borderColor: '#000',
        borderTopWidth: 2,
        backgroundColor: '#fff'
    },
})