import React from 'react'
import { StyleSheet, Animated, TouchableOpacity, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default function Checkbox({backgroundColor, checked, size, onPress, isRadio}) {

    const [selected, setSelected] = React.useState(false)

    const animation = React.useRef(new Animated.Value(0)).current
    const background = animation.interpolate({
        inputRange: [0, 1],
        outputRange: ['rgba(0, 0, 0, 0)', backgroundColor]
    })

    React.useEffect(() => {
        setSelected(checked)
    }, [checked])

    React.useEffect(() => {
        Animated.spring(animation, {
            toValue: selected ? 1 : 0,
            speed: 25,
            useNativeDriver: false
        }).start()
    }, [selected])

    function toggleSelect() {
        if (isRadio && selected) return
        setSelected(!selected)
        onPress(selected)
    }

    return (
        <TouchableOpacity delayPressIn={0} onPress={toggleSelect}>
            <Animated.View style={[styles.container, {backgroundColor: background, height: size, width: size, borderRadius: size, borderColor: backgroundColor}]}>
                {selected && <FontAwesomeIcon icon={faCheck} size={size / 2} color={'#fff'} style={{paddingTop: 1, paddingLeft: 1}}/>}
            </Animated.View>
        </TouchableOpacity>
    )
}

export function CheckboxRow({title, value, checked, onPress, isRadio}) {
    const [selected, setSelected] = React.useState(false)

    React.useEffect(() => {
        setSelected(checked)
    }, [checked])

    function toggleSelect() {
        if (isRadio && selected) return
        setSelected(!selected)
        onPress(value)
    }

    return (
        <TouchableOpacity style={styles.rowContainer} onPress={toggleSelect}>
            <Checkbox
                backgroundColor={'#000'} 
                size={25} 
                checked={selected}
                onPress={toggleSelect}
                isRadio={isRadio}
            />
            <Text style={styles.rowText}>{title}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1
    },
    rowContainer: {
        flexDirection: 'row', 
        marginVertical: 5, 
        alignItems: 'center'
    },
    rowText: {
        fontSize: 22,
        fontWeight: '500',
        marginVertical: 5,
        marginHorizontal: 15
    },
})