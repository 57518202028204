import React from "react";
import { StyleSheet, Text, View, TouchableOpacity, FlatList, TextInput } from "react-native";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import Card, { CardBackground } from "./components/Card";
import Container from "./components/Container";
import Deck from "./components/Deck";
import { isMobile } from 'react-device-detect'
import { CheckboxRow } from "./components/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery'
import Loader from "./components/Loader";
const ReactSwal = withReactContent(Swal)

export default function Game({ socket, roomData }) {

  function playersReducer(state, action) {
    const newPlayers = Array.from(state)
    const player = action.player
    switch (action.type) {
      case 'add': {
        newPlayers.push(player)
        return newPlayers
      }
      case 'update': {
        for (let i = 0; i < newPlayers.length; i++) {
          if (newPlayers[i].socketID === player.socketID) {
            newPlayers[i] = player
          }
        }
        return newPlayers
      }
      case 'remove': {
        const index = newPlayers.findIndex(el => el.displayName === player.displayName)
        if (index > -1) {
          newPlayers.splice(index, 1)
        }
        return newPlayers
      }
      default:
        throw new Error()
    }
  }

  const ROOM_CODE = roomData.code
  const [statusText, setStatusText] = React.useState("Joining room...")
  const [joined, setJoined] = React.useState(false)
  const [gameIsPublic, setGameIsPublic] = React.useState(false)
  const [gameType, setGameType] = React.useState("Default")
  const [availablePacks, setAvailablePacks] = React.useState([])
  const [selectedPacks, setSelectedPacks] = React.useState([])
  const [isHost, setIsHost] = React.useState(roomData.host && roomData.host.socketID === socket.id)
  const [players, playersDispatch] = React.useReducer(playersReducer, roomData.players || [])
  const [gameStarted, setGameStarted] = React.useState(roomData.isInProgress)
  const [selectionStageActive, setSelectionStageActive] = React.useState(roomData.isSelectionStageActive)
  const [blackCard, setBlackCard] = React.useState(roomData.currentBlackCard && roomData.currentBlackCard.text)
  const [cardCzar, setCardCzar] = React.useState(roomData.cardCzar)
  const [cards, setCards] = React.useState([])
  const [currentSubmission, setCurrentSubmission] = React.useState([])
  const [submissions, setSubmissions] = React.useState([])
  const [roundWinner, setRoundWinner] = React.useState(roomData.roundWinner)
  const [gameWinner, setGameWinner] = React.useState(roomData.gameWinner)
  const [submissionsModalVisible, setSubmissionsModalVisible] = React.useState(false) 
  const [loading, setLoading] = React.useState(false)
  const [packsBeingFetched, setPacksBeingFetched] = React.useState(false)

  React.useEffect(() => {

    document.title = `Room ${ROOM_CODE} | Cards Lacking Originality`
    window.history.replaceState(null, document.title, `/join/${ROOM_CODE}`)

    socket.on('game started', () => setGameStarted(true))
    socket.on('round started', (room) => {
      ReactSwal.close()
      setCurrentSubmission([])
      updateRoom(room)
    })
    socket.on('player added', addPlayer)
    socket.on('player left', removePlayer)
    socket.on('player made submission', setSubmissions)
    socket.on('selection stage started', (submissions) => {
      setSelectionStageActive(true)
      setSubmissions(submissions)
    })
    socket.on('round ended', (winningPlayer) => {
      setRoundWinner(winningPlayer)
      playersDispatch({type: 'update', player: winningPlayer})
      setStatusText(`${winningPlayer.displayName} has won the round!`)
    })
    socket.on('game ended', (winningPlayer) => {
      setGameWinner(winningPlayer)
      playersDispatch({type: 'update', player: winningPlayer})
      setStatusText(`${winningPlayer.displayName} has won the game!`)
    })
    socket.on('error', logError)

    return () => {
      socket.removeAllListeners()
    }
  }, [])

  function logError(text) {
    setLoading(false)
    ReactSwal.fire("Error", text, 'error')
  }

  React.useEffect(() => {
    if (!joined) return showDisplayNamePrompt()

    if (!gameStarted) {
      setStatusText("Waiting for host to start the game...")
    }

  }, [joined, gameStarted])

  React.useEffect(() => {
    if (!cardCzar) return
    const isCardCzar = cardCzar.socketID === socket.id
    if (isCardCzar) {
      setStatusText(`You are the Card Czar.`)
    } else {
      setStatusText(`${cardCzar.displayName} is the Card Czar.`)
    }
  }, [cardCzar])

  React.useEffect(() => {
    socket.emit('change room privacy', ROOM_CODE, gameIsPublic)
  }, [gameIsPublic])

  async function checkDisplayName(displayName) {
    return new Promise(function(resolve, reject) {
      socket.emit('get players', ROOM_CODE, function(players) {
        const existingPlayer = players.find(player => player.displayName === displayName)
        if (existingPlayer) {
          reject("Player already exists with that nickname. Choose another one.")
        }
        resolve()
      })
    })
  }

  async function showDisplayNamePrompt() {
    const response = await ReactSwal.fire({
      title: 'Hi there!',
      input: 'text',
      text: 'Type in a nickname to get started!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Let's Go!",
      showLoaderOnConfirm: true,
      inputValidator: async (value) => {
        if (!value) {
          return Promise.resolve("You must write something!")
        }
      },
      preConfirm: async (value) => {
        return checkDisplayName(value).catch((err) => {
          ReactSwal.showValidationMessage(err)
        })
      }
    })

    if (response.isConfirmed) {
      const displayName = response.value
      socket.emit('add player', ROOM_CODE, displayName, addPlayer)
      setJoined(true)
      triggerShareModal()
    }
  }

  function triggerShareModal() {
    ReactSwal.fire({
      titleText: `Room ${ROOM_CODE}`,
      icon: 'info',
      html: <URLBox url={`https://cardslackingoriginality.com/join/${ROOM_CODE}`}/>,
      customClass: {
        content: 'modalContent'
      },
      confirmButtonText: 'Got it!',
    })
  }

  function addPlayer(player, room) {
    playersDispatch({type: 'add', player: player})
    updateRoom(room)
  }

  function removePlayer(player, room) {
    playersDispatch({type: 'remove', player: player})
    updateRoom(room)
  }

  function updateRoom(room) {
    const currentPlayer = room.players.find(player => player.socketID === socket.id)
    setBlackCard(room.currentBlackCard && room.currentBlackCard.text)
    setCards(currentPlayer.cards)
    setCardCzar(room.cardCzar)
    setGameType(room.gameType || "Default")
    setSelectionStageActive(room.isSelectionStageActive)
    setRoundWinner(room.roundWinner)
    setGameWinner(room.gameWinner)
    setSubmissions(room.submissions)
    setIsHost(room.host && currentPlayer.socketID === room.host.socketID)
  }

  function startGame() {
    setLoading(true)
    socket.emit('start game', ROOM_CODE, gameType, selectedPacks)
  }

  function startNextRound() {
    setLoading(true)
    socket.emit('start new round', ROOM_CODE)
  }

  function endGame() {
    setGameStarted(false)
  }

  async function makeCardSubmission(cardText) {
    let card
    if (gameType === 'Nothing But Blanks') {
      const response = await ReactSwal.fire({
        title: 'Blank Card',
        input: 'text',
        text: 'Enter your unfunny joke here:',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        inputValidator: async (value) => {
          if (!value) {
            return Promise.resolve("You must write something!")
          }
        }
      })
  
      if (response.isConfirmed) {
        card = {
          text: response.value
        }
      }
    } else {
      card = cards.find(card => card.text === cardText)
    }
    socket.emit('make submission', ROOM_CODE, card, (submission) => {
      setCurrentSubmission(submission)
    })
  }

  function pickWinningSubmission(submission) {
    socket.emit('pick winning submission', ROOM_CODE, submission)
  }

  React.useEffect(() => {
    async function fetchData() {
      const data = await getCardPacks()
      setAvailablePacks(data)
    }


    if (availablePacks.length === 0 && isHost) {
      fetchData()
    }
  }, [availablePacks, isHost])

  async function getCardPacks() {
    try {
      setPacksBeingFetched(true)
      const res = await $.ajax({
        type: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/packs/get`
      })

      if (!res.success) throw Error(res.message)

      const packs = res.packs
      setPacksBeingFetched(false)
      return Promise.resolve(packs)
    } catch (err) {
      setPacksBeingFetched(false)
      return Promise.reject(err.message)
    }
  }

  function selectPack(pack) {
    const newPacks = Array.from(selectedPacks)
    const index = newPacks.findIndex(el => el === pack)
    if (index === -1) {
      newPacks.push(pack)
    } else {
      newPacks.splice(index, 1)
    }
    setSelectedPacks(newPacks)
  }

  function Submission({player, cards, onPress}) {
    const submissionObject = {
      player: player,
      cards: cards
    }

    const roomPlayer = players.find(el => el.socketID === player.socketID)
    const disabled = cardCzar.socketID !== socket.id
    const isWinner = roundWinner && roundWinner.socketID === roomPlayer.socketID

    const cardObjects = cards.map((card, index) => {
      return (
        <View style={submissionViewStyles.cardContainer}>
          <Card 
            key={`card-${index}`} 
            text={card.text} 
            type={'white'} 
            scale={!isMobile ? 0.95 : 0.9} 
            borderColor={isWinner ? '#00FF00' : '#000'} 
          />
        </View>
      )
    })

    return (
      <TouchableOpacity style={{alignItems: 'center', opacity: disabled && 0.6}} onPress={() => onPress(submissionObject)} disabled={disabled || roundWinner}>
        <View style={submissionViewStyles.cardsContainer}>
          {cardObjects}
        </View>
        {(roundWinner || gameWinner) && <Text style={submissionViewStyles.displayNameText}>{`${roomPlayer.displayName}: ${roomPlayer.points} points`}</Text>}
      </TouchableOpacity>
    )
  }

  function SubmissionsView() {
    const submissionObjects = submissions.map((submission, index) => {
      return <Submission key={`submission-${index}`} player={submission.player} cards={submission.cards} onPress={pickWinningSubmission} />
    })

    const isCardCzar = cardCzar.socketID === socket.id

    return (
      <View style={submissionViewStyles.container}>
        <Card text={blackCard} type={'black'} scale={!isMobile ? 1.8 : 1.15} margin={0} />
        <View style={submissionViewStyles.content}>
          {submissionObjects}
        </View>
        {(roundWinner && isCardCzar) &&
          <Button title={"Next Round"} onPress={startNextRound}/>
        }
        {(gameWinner && isHost) &&
          <Button title={"New Game"} onPress={endGame}/>
        }
      </View>
    )
  }

  return (
    <Container logoPressable={false}>
      <View style={styles.content}>
        <Text style={styles.statusText}>{statusText}</Text>
        {gameStarted ? (
          <View style={styles.gameContainer}>
            {!selectionStageActive ?
              <>
                <View style={styles.gameContent}>
                  <Card text={blackCard} type={'black'} scale={!isMobile ? 1.8 : 1.15} margin={0} />
                  <View style={styles.submissionsContainer}>
                    <CardBackground scale={!isMobile ? 0.95 : 0.8} margin={30}>
                      <Text style={submissionViewStyles.counterText}>{`${submissions.length}/${players.length - 1}`}</Text>
                      <Text style={submissionViewStyles.subtitleText}>{'Players submitted'}</Text>
                    </CardBackground>
                  </View>
                </View>
                <Deck 
                  cards={cards} 
                  onCardPress={makeCardSubmission} 
                  selectedCards={currentSubmission.cards} 
                  disabled={cardCzar && cardCzar.socketID === socket.id}
                />
              </>
              : 
              <SubmissionsView/>
            }
          </View>
        ) 
        : 
        (
          <View style={menuStyles.container}>
          <View style={menuStyles.column}>
            <FlatList
              style={menuStyles.listContainer}
              contentContainerStyle={menuStyles.listContent}
              data={players}
              keyExtractor={(item) => item.socketID}
              renderItem={({ item }) => <Text style={menuStyles.listItemText}>{item.displayName}</Text>}
              ListHeaderComponent={<Text style={menuStyles.listHeaderText}>{`Players: (${players.length}/${roomData.maxPlayers})`}</Text>}
            />
            {isHost && 
              <>
                <Button title={"Start Game"} onPress={startGame} loading={loading} />
                {players.length < roomData.minPlayers && <Text>{`Game must have ${roomData.minPlayers} or more players before starting.`}</Text>}
              </>
            }
          </View>
          {isHost && 
            <View style={[menuStyles.column, {flexGrow: 3}]}>
              <View style={{marginBottom: 20}}>
                <Text style={menuStyles.listHeaderText}>{'Game Settings:'}</Text>
                <CheckboxRow
                    title={'Public'} 
                    // value={'Public'}
                    checked={gameIsPublic}
                    onPress={() => setGameIsPublic(!gameIsPublic)}
                />
              </View>
              <View style={{marginBottom: 20}}>
                <Text style={menuStyles.listHeaderText}>{'Game Mode:'}</Text>
                <CheckboxRow
                    title={'Default'}
                    value={'Default'}
                    checked={gameType === "Default"}
                    onPress={setGameType}
                    isRadio={true}
                />
                <CheckboxRow
                    title={'Nothing But Blanks'}
                    value={'Nothing But Blanks'}
                    checked={gameType === "Nothing But Blanks"}
                    onPress={setGameType}
                    isRadio={true}
                />
              </View>
              <View style={{marginBottom: 20}}>
                <Text style={menuStyles.listHeaderText}>{'Card Packs:'}</Text>
                <FlatList
                  data={availablePacks}
                  renderItem={({ item }) => (
                    <CheckboxRow
                      title={`${item.name} (${item.blackCardsCount}B/${item.whiteCardsCount}W)`}
                      value={item.id}
                      checked={selectedPacks.includes(item.id)}
                      onPress={selectPack}
                    />
                  )}
                  ListHeaderComponent={packsBeingFetched && <Loader color={'#000'}/>}
                />
              </View>
            </View>
          }
        </View>
        )}
        </View>
    </Container>
  );
}

function Button({title, onPress, loading, disabled}) {
  return (
    <TouchableOpacity style={styles.button} onPress={onPress} disabled={disabled}>
      {!loading ? (
        <Text style={styles.buttonText}>{title}</Text>
      ) : (
        <Loader/>
      )}
    </TouchableOpacity>
  )
}

function URLBox({url}) {
  return (
    <View>
      <View style={urlBoxStyles.container}>
        <TextInput 
          style={urlBoxStyles.inputField} 
          value={url} 
          editable={false}
          selectTextOnFocus={true}
        />
        <TouchableOpacity style={urlBoxStyles.button} onPress={() => navigator.clipboard.writeText(url)}>
          <FontAwesomeIcon icon={faClipboard} size={20} color={'#fff'} />
        </TouchableOpacity>
      </View>
      <Text style={urlBoxStyles.subtitleText}>Share this link or code to play with your friends!</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 20,
    paddingHorizontal: !isMobile ? 30 : 20
  },
  gameContainer: {
    flex: 1
  },
  gameContent: {
    flex: 1,
    flexDirection: 'row'
  },
  statusText: {
    fontSize: !isMobile ? 28 : 26,
    fontWeight: '700',
    marginVertical: 10
  },
  button: {
    flexDirection: 'row',
    width: 200,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 15,
    paddingHorizontal: 25,
    marginVertical: 20
  },
  buttonText: {
    fontSize: 18,
    fontWeight: '600',
    color: '#fff',
    marginLeft: 10
  }
});

const submissionViewStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 20
  },
  counterText: {
    fontSize: 26,
    fontWeight: '700',
    marginVertical: 5,
    textAlign: 'center'
  },
  subtitleText: {
    fontSize: 18,
    fontWeight: '500',
    marginVertical: 5,
    textAlign: 'center'
  },
  cardsContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  cardContainer: {
    // marginHorizontal: -30
  },
  displayNameText: {
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center'
  },
});

const menuStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  column: {
    flex: 2,
    minWidth: 350,
    marginVertical: 10,
    alignItems: 'flex-start'
  },
  listContainer: {
    flexGrow: 0,
    marginVertical: 15
  },
  listContent: {
    
  },
  listHeaderText: {
    fontSize: 26,
    fontWeight: '700',
    marginVertical: 10
  },
  listItemText: {
    fontSize: 22,
    fontWeight: '500',
    marginVertical: 5
  },
  checkbox: {
    width: 40,
    height: 40
  }
});

const urlBoxStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'stretch',
    marginVertical: 15,
  },
  inputField: {
    width: !isMobile ? 350 : 300,
    padding: 12,
    fontSize: 16,
    borderColor: '#2778c4',
    borderWidth: 2.5,
    fontWeight: '700',
    color: '#61acf3'
  },
  button: {
    backgroundColor: '#2778c4',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center'
  },
  subtitleText: {
    color: '#595959',
    fontSize: 18,
    fontWeight: '500',
    marginVertical: 5,
    textAlign: 'center',
  }
});
