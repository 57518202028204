import { StyleSheet, Text, View } from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Loader({ textVisible = true, color = '#fff' }) {
    return (
        <View style={styles.container}>
          <FontAwesomeIcon icon={faSpinner} size={20} color={color} spin={true} />
          {textVisible && <Text style={[styles.text, {color: color}]}>{"Loading"}</Text>}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'center',
    },
    text: {
        fontSize: 18,
        fontWeight: '600',
        color: '#fff',
        marginLeft: 10
    }
})