import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native'
import LOGO_DARK from './../assets/clo_logo_long.png'
import LOGO_WHITE from './../assets/clo_logo_long_white.png'

export default function Card({text, type, scale = 1, margin = 10, borderColor = '#000'}) {

    const backgroundColor = type === 'black' ? '#000' : '#fff'
    const textColor = type === 'black' ? '#fff' : '#000'
    const height = 200 * scale
    const width = height * 0.75
    const textSize = type === 'black' ? 12 * scale : 14 * scale
    const lineHeight = 20 * scale
    const logo = type === 'black' ? LOGO_WHITE : LOGO_DARK
    const logoHeight = 18 * scale
    const logoWidth = 110 * scale
    const borderWidth = borderColor !== '#000' ? 4 : 2

    return (
        <View style={[styles.container, {backgroundColor: backgroundColor, borderColor: borderColor, borderWidth: borderWidth, height: height, width: width, marginHorizontal: margin}]}>
            <Text style={[styles.text, {fontSize: textSize, color: textColor, lineHeight: lineHeight}]}>{text}</Text>
            <Image source={logo} style={[styles.logo, {height: logoHeight, width: logoWidth}]}/>
        </View>
    )
}

export function ClickableCard({text, type, onPress, scale, disabled}) {
    return (
        <TouchableOpacity onPress={() => onPress(text)} disabled={disabled} style={{opacity: disabled && 0.6}}>
            <Card text={text} type={type} scale={scale} borderColor={'#000'} />
        </TouchableOpacity>
    )
}

export function CardBackground({children, scale = 1, margin = 10}) {
    const height = 200 * scale
    const width = height * 0.75

    return (
        <View style={[styles.container, {alignItems: 'center', justifyContent: 'center', height: height, width: width, marginHorizontal: margin}]}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        padding: 12.5,
        margin: 15,
        height: 200,
        width: 200 * 0.75,
        backgroundColor: '#fff',
        borderColor: '#000',
        shadowOffset: {
            width: 3,
            height: 3
        },
        shadowColor: 'grey',
        shadowRadius: 5,
        borderWidth: 2,
        borderRadius: 20,
        justifyContent: 'space-between'
    },
    text: {
        color: '#000',
        fontWeight: '600',
        lineHeight: 20
    },
    logo: {
        resizeMode: "contain",
        height: 16,
        width: 95
    }
})